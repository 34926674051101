.md {
  width: 100%;
}

.md h4 {
  margin-bottom: 0;
}

.md p {
  margin-top: 10px;
}

.md-light {
  color: black;
}

.md-dark {
  color: white;
}

.md a:visited,
.md a:link,
.md a:active,
.md a:hover {
  color: var(--primary);
  filter: brightness(1.1);
}

.md a:hover {
  filter: brightness(1.25);
}
